import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  您好.
    </Text>
    <p>{`我是程序扫地僧，我在这里分享虚拟现实相关的技术知识。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      